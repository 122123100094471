import gallery1 from "../images/gallery_2021/gallery_1.jpg"
import gallery2 from "../images/gallery_2021/gallery_2.jpg"
import gallery3 from "../images/gallery_2021/gallery_3.jpg"
import gallery4 from "../images/gallery_2021/gallery_4.jpg"
import gallery5 from "../images/gallery_2021/gallery_5.jpg"
import gallery6 from "../images/gallery_2021/gallery_6.jpg"
import gallery7 from "../images/gallery_2021/gallery_7.jpg"
import gallery8 from "../images/gallery_2021/gallery_8.jpg"
import gallery9 from "../images/gallery_2021/gallery_9.jpg"
import gallery10 from "../images/gallery_2021/gallery_10.jpg"
import gallery11 from "../images/gallery_2021/gallery_11.jpg"
import gallery12 from "../images/largerVersion/image1.JPG"
import gallery13 from "../images/largerVersion/image2.jpg"
import gallery14 from "../images/largerVersion/image3.JPG"
import gallery15 from "../images/largerVersion/image4.jpg"
import gallery16 from "../images/pictures/image6.jpg"
import gallery17 from "../images/largerVersion/image5.jpg"
import gallery18 from "../images/largerVersion/image6.jpg"
import gallery19 from "../images/pictures/image9.jpg"
import gallery20 from "../images/largerVersion/image7.jpg"
import gallery21 from "../images/pictures/image11.jpg"
import gallery22 from "../images/largerVersion/image8.JPG"
import gallery23 from "../images/pictures/image13.jpg"
import gallery24 from "../images/pictures/image14.jpg"
import gallery25 from "../images/pictures/image15.jpg"
import gallery26 from "../images/pictures/image16.jpg"

const images = [
  gallery1,
  gallery2,
  gallery3,
  gallery4,
  gallery5,
  gallery6,
  gallery7,
  gallery8,
  gallery9,
  gallery10,
  gallery11,
  gallery12,
  gallery13,
  gallery14,
  gallery15,
  gallery16,
  gallery17,
  gallery18,
  gallery19,
  gallery20,
  gallery21,
  gallery22,
  gallery23,
  gallery24,
  gallery25,
  gallery26
]
export default images