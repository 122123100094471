import React, { Component } from "react"
import VacancyAlert from './VacancyAlert'
export default class Ofested extends Component {
  render() {
    return (
      <div className='col s12 homeWrapper'>
        <VacancyAlert />
        <h5 className='blue-text text-lighten-2'>Ofsted</h5>
        <p className='grey-text text-darken-2'>
          Ofsted is the Office for Standards in Education, Children’s Services
          and Skills. They report directly to Parliament and they are
          independent and impartial. Ofsted inspect and regulate services which
          care for children and young people, and those providing education and
          skills for learners of all ages.
        </p>
        <br />
        <p className='grey-text text-darken-2'>
          All Saints Preschool is regularly inspected by Ofsted Early Years. We
          are governed by the size of the room & the number of staff we have.
          The ratio must be 1:8, the ratio is 1:8 for 3/4 year olds, and 1:4 for
          2 year olds.
        </p>
        <br />
        <p className='grey-text text-darken-2'>
          Ofsted Early Years visits our facility regularly and ensures that we
          meet all standard requirements required for running a pre-school
          setting. We are proud that we achieved GOOD at our last Ofsted
          inspection.
        </p>
        <br />
        <a href='https://reports.ofsted.gov.uk/provider/16/508999'>
          Visit our Oftsed Report Page
        </a>
      </div>
    )
  }
}
